<template>
  <div>
    <div
      class="headerStep"
      :class="currentStep == steps.length - 1 ? 'active' : ''"
    >
      <div class="container">
        <div class="headerScroll d-flex" v-if="!isCreate">
          <div
            v-for="(step, i) in steps"
            :key="i"
            @click="currentStep = i"
            :class="'headerItem ' + (i <= currentStep ? 'active' : '')"
          >
            Step {{ i + 1 }} - {{ step.title }}
          </div>
        </div>
        <div class="headerScroll d-flex" v-else>
          <div
            v-for="(step, i) in steps"
            :key="i"
            @click="currentStep = i"
            :class="'headerItem ' + (i <= currentStep ? 'active' : '')"
          >
            Step {{ i + 1 }} - {{ step.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="container wrapper">
      <div v-if="!isLive">
        <template v-if="me.isUnder18 && currentStep == 1">
          <h1 class="pageHeader mt-12 mb-3">
            {{ $t('label.guardiansInformation') }}
          </h1>
          <p class="text-lg-h6 text-xl-h5">
            {{ $t('message.ifYouAreUnderTheAgeOf18') }}
          </p>
        </template>
        <h1 class="pageHeader mt-12 text-uppercase" v-else>Profile Info</h1>
        <div class="w-100 text-center py-12">
          <Component
            :is="steps[currentStep].component"
            v-bind="steps[currentStep].props"
            @next="steps[currentStep].actions.next"
            @doItLater="steps[currentStep].actions.doItLater"
            @input="steps[currentStep].actions.input"
          />
        </div>

        <!-- <UserInfo
          :userInfo="me"
          :isCreate="isCreate"
          v-if="currentStep == 0"
          @next="next"
          @doItLater="currentStep = 1"
          @input="formValues = { ...formValues, ...$event }"
        />
        <BioInfo
          :userInfo="me"
          :isCreate="isCreate"
          v-if="currentStep == 1"
          @next="next"
          @doItLater="currentStep = 2"
          @input="formValues = { ...formValues, ...$event }"
        />
        <InterestInfo
          :userInfo="me"
          :isCreate="isCreate"
          v-if="currentStep == 2"
          @next="next"
          @doItLater="currentStep = 3"
          @input="formValues = { ...formValues, ...$event }"
        />
        <BioIntroInfo
          :userInfo="me"
          :isCreate="isCreate"
          :skills="skills"
          v-if="currentStep == 3"
          @next="next"
          @doItLater="currentStep = 4"
          @input="formValues = { ...formValues, ...$event }"
        />
        <SocialPlatform
          :userInfo="me"
          :isCreate="isCreate"
          v-if="currentStep == 4"
          @input="formValues = { ...formValues, ...$event }"
          @next="createProfile()"
        /> -->
      </div>
      <div class="w-100 text-center py-12" v-if="isLive">
        <h1 class="pageHeader mx-12 my-12 px-12 text-left" style="color: white">
          <div>Your profile is completed.</div>
          <div class="pb-12">It is now live!</div>
        </h1>
        <div class="d-flex justify-center pt-12">
          <v-btn class="btn1" @click="$router.push('/profile')">
            See Profile
          </v-btn>
        </div>
        <div class="d-flex justify-center pt-7 pb-10">
          <div class="underlineBtn" @click="$router.push('/')">
            Back to home
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserInfo from '@/components/profile/UserInfo.vue';
  import BioInfo from '@/components/profile/BioInfo.vue';
  import InterestInfo from '@/components/profile/InterestInfo.vue';
  import BioIntroInfo from '@/components/profile/BioIntroInfo.vue';
  import SocialPlatform from '@/components/profile/SocialPlatform.vue';
  import GuardianInfo from '@/components/profile/GuardianInfo.vue';
  import { AUTH_ME } from '@/store/auth.module';
  import {
    LOOKUP_GET_SKILLS,
    LOOKUP_INITIAL_GET_SKILLS_STATE
  } from '@/store/lookup.module';
  import { PROFILE } from '@/api';
  import { LOCAL } from '@/constants';
  import { uiHelper } from '@/utils';
  import { mapGetters } from 'vuex';
  import { ROUTE_NAME } from '@/constants';
  import uploadMediaMixin from '@/mixin/upload-media.mixin';
  import { bus } from '@/main.js';

  export default {
    name: 'ProfileInfo',
    mixins: [uploadMediaMixin],
    data: () => ({
      currentStep: 0,
      isLive: false,
      isCreate: false,
      formValues: {},
      media: [
        {
          key: 'cv_file',
          mediaCollectionsName: 'cv'
        },
        {
          key: 'intro_video_files',
          mediaCollectionsName: 'intro_video'
        },
        {
          key: 'gallery_file',
          mediaCollectionsName: 'gallery'
        },
        {
          key: 'headshot_file',
          mediaCollectionsName: 'headshot'
        }
      ],
      skills: []
    }),
    computed: {
      me() {
        return this.$store.state.auth.me.data;
      },
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      steps() {
        const userInfoProp = this.isCreate ? this.formValues : this.me;
        let steps = [
          {
            title: 'User Info',
            component: UserInfo,
            props: {
              userInfo: userInfoProp,
              isCreate: this.isCreate
            },
            actions: {
              next: (e) => this.next(e),
              doItLater: () => this.currentStep++,
              input: (e) =>
                (this.formValues = {
                  ...this.formValues,
                  ...e
                })
            }
          },
          {
            title: 'Bio Info',
            component: BioInfo,
            props: {
              userInfo: userInfoProp,
              isCreate: this.isCreate
            },
            actions: {
              next: (e) => this.next(e),
              doItLater: () => this.currentStep++,
              input: (e) =>
                (this.formValues = {
                  ...this.formValues,
                  ...e
                })
            }
          },
          {
            title: 'Interest Info',
            component: InterestInfo,
            props: {
              userInfo: userInfoProp,
              isCreate: this.isCreate
            },
            actions: {
              next: (e) => this.next(e),
              doItLater: () => this.currentStep++,
              input: (e) =>
                (this.formValues = {
                  ...this.formValues,
                  ...e
                })
            }
          },
          {
            title: 'Bio Intro Info',
            component: BioIntroInfo,
            props: {
              userInfo: userInfoProp,
              isCreate: this.isCreate,
              skills: this.skills
            },
            actions: {
              next: (e) => this.next(e),
              doItLater: () => this.currentStep++,
              input: (e) =>
                (this.formValues = {
                  ...this.formValues,
                  ...e
                })
            }
          },
          {
            title: 'Social Platform',
            component: SocialPlatform,
            props: {
              userInfo: userInfoProp,
              isCreate: this.isCreate
            },
            actions: {
              next: () => this.createProfile(),
              doItLater: () => {},
              input: (e) =>
                (this.formValues = {
                  ...this.formValues,
                  ...e
                })
            }
          }
        ];
        if (this.me.isUnder18)
          steps.splice(1, 0, {
            title: 'Guardian Info',
            component: GuardianInfo,
            props: {
              userInfo: userInfoProp,
              isCreate: this.isCreate
            },
            actions: {
              next: (e) => this.next(e),
              doItLater: () => this.currentStep++,
              input: (e) =>
                (this.formValues = {
                  ...this.formValues,
                  ...e
                })
            }
          });
        return steps;
      },
      ...mapGetters({
        mediaCollections: 'constants/mediaCollections'
      }),
      getSkillsComplete() {
        return this.$store.state.lookup.skills.complete;
      }
    },
    watch: {
      async currentStep(val) {
        this.$scrollToTop();
        if (!this.isCreate) await this.getMe();
        if (val != -1) this.isLive = false;
      },
      getSkillsComplete() {
        let response = this.$store.state.lookup.skills;
        if (response.complete) {
          this.importSkills(response);
        }
      }
    },
    methods: {
      async getMe() {
        await this.$store.dispatch(AUTH_ME);
      },
      async getSkills() {
        await this.$store.dispatch(LOOKUP_GET_SKILLS);
      },
      importSkills(response) {
        if (response.code == 200) {
          this.skills = response.data;
        }
        this.$store.dispatch(LOOKUP_INITIAL_GET_SKILLS_STATE);
      },
      next(e) {
        if (this.isCreate) {
          this.formValues = {
            ...this.formValues,
            ...e
          };
        }
        this.$nextTick(() => (this.currentStep += 1));
      },
      async createProfile() {
        if (!this.isCreate) {
          await this.getMe();
          this.navigateToDisplayInfo();
        } else {
          this.$startLoading();
          try {
            const profile = await PROFILE.createProfile(
              this.formValues,
              this.me.role
            );
            const profileId = profile.data.profile.profile.profile_id;
            await this.saveMedias(profileId);
            this.$closeModal();
            await this.$store.dispatch(AUTH_ME);
            this.navigateToDisplayInfo();
          } catch (e) {
            console.log(`e: ${e}`);
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
          this.$stopLoading();
        }
      },
      saveMedias(id) {
        const openAppDialogInfo = (e, isVideo = false) => {
          let errMsg = isVideo
            ? 'Upload Failed, Please check your internet connection and try again'
            : 'Request Failed';
          this.openAppDialogInfo('error', e.error_message || errMsg, '', [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ]);
        };

        const singleFileKey = ['cv', 'headshot'];
        this.media.forEach(async (m) => {
          const mediaId = this.mediaCollections.indexOf(
            `profiles.${m.mediaCollectionsName}`
          );
          if (
            singleFileKey.includes(m.mediaCollectionsName) &&
            !this.formValues[m.key]?.id &&
            this.formValues[m.key]
          ) {
            let data = {
              file: this.formValues[m.key].file
            };
            await PROFILE.uploadFile(id, data, mediaId).catch((e) => {
              this.formValues[m.key] = null;
              openAppDialogInfo(e);
            });
          } else if (!singleFileKey.includes(m.mediaCollectionsName)) {
            if ([2, 5].includes(mediaId)) {
              let values = this.formValues[m.key];
              this.$pushVideoToCompress({
                file: values,
                extra: {
                  profileId: this.userInfo?.profile?.profile_id,
                  mediaId: 2
                }
              });
              // await this.processValue(
              //   [values],
              //   {
              //     profileId: this.userInfo?.profile?.profile_id,
              //     mediaId: 5
              //   }
              // )
            } else {
              this.formValues[m.key].map(async (item, idx) => {
                if (!item || item.id) return;
                let tempData = {
                  file: item.file,
                  seq: idx + 1
                };
                return await PROFILE.uploadFile(id, tempData, mediaId).catch(
                  (e) => {
                    openAppDialogInfo(e, [2, 5].includes(mediaId));
                  }
                );
              });
            }
          }
        });
      },
      async uploadFile(img, mediaId, index, id) {
        let data = {
          file: img.file
        };
        if (id == 6) {
          data.seq = index + 1;
        }
        await PROFILE.uploadFile(id, data, mediaId);
      },
      navigateToDisplayInfo() {
        this.openAppDialogInfo(
          'success',
          `Profile ${this.isCreate ? 'created' : 'updated'}`,
          '',
          [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
                this.$router.push({
                  name: ROUTE_NAME.DISPLAY_INFORMATIONS,
                  params: {
                    navigateToEditProfile: true
                  }
                });
              }
            }
          ]
        );
      },
      goLive() {
        this.currentStep = -1;
        this.isLive = true;
      }
    },
    async mounted() {
      await this.getMe();
      await this.getSkills();
      if (!this.me.profile) {
        this.isCreate = true;

        const findVal = (key, val) => {
          return this.constantsData[key].find((x) => x.description === val)
            ?.value;
        };

        this.formValues = {
          ...this.me,
          gender: findVal('gender', this.me.gender)
        };
      }
      if (this.$route.params?.goLive) this.goLive();
      else if (this.$route.params?.lastStep) this.currentStep = 4;
      // else this.currentStep += 1;
    },
    created() {
      bus.$on('refreshProfile', async () => {
        await this.getMe();
      });
    }
  };
</script>
<style lang="scss"></style>
