<template>
  <div>
    <div class="mb-5 text-left md-font">{{ $t('label.talent') }}</div>
    <div class="role-wrapper d-flex">
      <div class="select-all_text" @click="addOrRemoveType(null)">
        {{ hasSelectedAllRoles ? $t('label.clear') : $t('label.selectAll') }}
      </div>
      <div
        v-for="(role, index) in projectRoles"
        :key="index"
        class="project-roles_tab"
        :class="selectedProjectRoleIds.includes(role.value) ? 'active' : ''"
        @click="addOrRemoveType(role.value)"
      >
        {{ role.text }}
      </div>
    </div>
    <div class="project-types_wrapper">
      <template v-for="(role, index) in formValues.project_role_type_ids">
        <div :key="index" class="project-types_container">
          <div class="d-flex mb-4 px-1">
            <div class="role-name">
              {{ getRoleName(role.role_id) }}
            </div>
            <div
              class="ml-auto select-all_text"
              @click="selectAllProjectType(role.role_id)"
            >
              {{
                selectOrClearText(role.role_id)
                  ? $t('label.clear')
                  : $t('label.selectAll')
              }}
            </div>
          </div>
          <div class="type_box">
            <v-checkbox
              v-for="(type, index) in role.type_id"
              v-model="type.selected"
              :key="index"
              :label="type.name"
              dark
              color="primary"
              hide-details
              class="shrink"
            ></v-checkbox>
          </div>
        </div>
      </template>
    </div>
    <v-row no-gutters class="text-center mt-12 pb-2">
      <v-col cols="12" md="12">
        <v-btn class="btn1 mb-3" @click="save"> Save & Next </v-btn>
      </v-col>
      <v-col cols="12" md="12">
        <div class="underlineBtn mb-3" @click="$emit('doItLater')">
          Do it later
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {
    PROFILE_UPDATE_INTEREST,
    PROFILE_INITIAL_UPDATE_INTEREST_STATE
  } from '@/store/profile.module';

  export default {
    name: 'InterestInfo',
    props: {
      userInfo: {
        type: Object,
        default: () => ({})
      },
      isCreate: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        formValues: {
          project_role_type_ids: []
        },
        selectedProjectRoleIds: []
      };
    },
    computed: {
      projectTypes() {
        return this.$store?.getters['constants/project_type_options'];
      },
      projectRoles() {
        return this.$store?.getters['constants/projectRoles'];
      },
      updateInterestComplete() {
        return this.$store.state.profile.updateInterest.complete;
      },
      hasSelectedAllRoles() {
        return this.projectRoles.every((x) =>
          this.selectedProjectRoleIds.includes(x.value)
        );
      }
    },
    watch: {
      updateInterestComplete() {
        let response = this.$store.state.profile.updateInterest;
        if (response.complete) {
          this.initialUpdateInterestState();
        }
      }
    },
    methods: {
      addOrRemoveType(id) {
        const getTypes = (roleId) => {
          return this.projectRoles
            .find((x) => x.value == roleId)
            .projectTypeIds.map((t) => ({
              name: t.name,
              id: t.id,
              selected: false
            }));
        };

        let selected =
          this.formValues.project_role_type_ids.find((x) => x.role_id == id) ??
          null;
        if (!id) {
          this.formValues.project_role_type_ids = this.hasSelectedAllRoles
            ? []
            : this.projectRoles.map((x) => ({
                role_id: x.value,
                type_id: getTypes(x.value)
              }));
        } else if (selected) {
          this.formValues.project_role_type_ids =
            this.formValues.project_role_type_ids.filter(
              (x) => x.role_id !== id
            );
        } else {
          this.formValues.project_role_type_ids.push({
            role_id: id,
            type_id: getTypes(id)
          });
        }
        this.selectedProjectRoleIds = this.formValues.project_role_type_ids.map(
          (x) => x.role_id
        );
        this.$emit('input', this.formatData());
      },
      selectOrClearText(roleId) {
        let selectedRole = this.formValues.project_role_type_ids.find(
          (x) => x.role_id == roleId
        );
        const mappedSelectedIds = selectedRole.type_id
          .filter((t) => t.selected)
          .map((t) => t.id);
        const mappedIds = this.projectRoles
          .find((x) => x.value == roleId)
          .projectTypeIds.map((t) => t.id);

        return mappedIds.every((x) => mappedSelectedIds.includes(x));
      },
      selectAllProjectType(roleId) {
        let selectedRole = this.formValues.project_role_type_ids.find(
          (x) => x.role_id == roleId
        );
        const hasAll = this.selectOrClearText(roleId);
        selectedRole.type_id = selectedRole.type_id.map((t) => ({
          ...t,
          selected: !hasAll
        }));
        this.$emit('input', this.formatData());
      },
      getRoleName(roleId) {
        return this.projectRoles.find((x) => x.value == roleId).text;
      },
      formatData() {
        const data = {
          project_role_type_ids: this.formValues.project_role_type_ids.map(
            (r) => ({
              role_id: r.role_id,
              type_id: r.type_id.filter((t) => t.selected).map((t) => t.id)
            })
          )
        };
        return data;
      },
      save() {
        if (!this.isCreate) {
          const data = this.formatData();
          this.$store.dispatch(PROFILE_UPDATE_INTEREST, {
            data
          });
        } else {
          this.$emit('next', this.formatData());
        }
      },
      initialUpdateInterestState() {
        this.$store.dispatch(PROFILE_INITIAL_UPDATE_INTEREST_STATE);
        this.$emit('next', this.formatData());
      },
      input() {
        this.$emit('input', this.formatData());
      }
    },
    mounted() {
      const allProjectRoleIds = this.projectRoles.map((r) => r.value);
      this.formValues = {
        project_role_type_ids:
          this.userInfo.project_role_type_ids.map((x) => {
            if (!allProjectRoleIds.includes(x.role_id)) return;
            const projectRoleConstant = this.projectRoles.find(
              (r) => r.value == x.role_id
            );
            let typeIds = projectRoleConstant.projectTypeIds.map((t) => ({
              id: t.id,
              selected: x.type_id.includes(t.id),
              name: t.name
            }));
            return {
              role_id: x.role_id,
              type_id: typeIds
            };
          }) ?? []
      };
      this.selectedProjectRoleIds = this.formValues.project_role_type_ids.map(
        (x) => x.role_id
      );
    }
  };
</script>

<style scoped lang="scss">
  .projectRolesContainer {
    border-radius: 5px;
    background-color: #242424;
  }
  .project-roles_tab {
    border-radius: 20px;
    padding: 5px 20px;
    margin: 5px 10px;
    background-color: #242424;
    cursor: pointer;

    &.active {
      background-color: #ff2ff2;
    }
  }

  .role-wrapper {
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;

    .select-all_text {
      width: 100px;
    }
  }

  .select-all_text {
    text-transform: capitalize;
    cursor: pointer;
    align-self: center;
    &:hover {
      text-shadow: 0 0 white;
    }
  }

  .project-types_wrapper {
    min-height: 200px;
    display: flex;
    overflow-x: auto;
    padding-bottom: 2rem;
    scrollbar-width: auto;
    scrollbar-color: rgba(235, 235, 235, 0.2) transparent;
    &::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(235, 235, 235, 0.2);
      border-radius: 10px;
      // border: 3px solid #242424;
    }

    .project-types_container {
      background-color: #242424;
      padding: 1.5rem 1rem;
      height: 550px;
      min-width: 450px;
      margin: 1rem;
      border-radius: 5px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .type_box {
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        height: 450px;
        overflow-y: auto;
        scrollbar-width: auto;
        scrollbar-color: #ebebeb transparent;

        .v-input--selection-controls__input:hover
          .v-input--selection-controls__ripple:before {
          transform: scale(1);
        }

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ebebeb;
          border-radius: 10px;
          border: 3px solid #242424;
        }

        .v-input--selection-controls:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
  .role-name {
    cursor: default;
    font-weight: bold;
    font-size: 1rem;
  }
</style>
