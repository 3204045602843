<template>
  <div>
    <div class="pageContent">
      <v-form
        v-model="guardianInfoFormValid"
        ref="guardianInfoForm"
        :class="[{ addOn: formValues.guardian_relationship == 'other' }]"
      >
        <v-row no-gutters justify="space-between" class="w-100 pb-12">
          <v-col
            class="input-col"
            v-for="(formField, index) in guardianInfoFormFields"
            :key="index"
            cols="12"
            :md="formField.key === 'guardian_gender' ? 12 : 6"
          >
            <AppFormField
              v-if="formField.field !== 'other'"
              v-model="formValues[formField.key]"
              outlined
              dark
              :type="formField.formType"
              :height="40"
              :items="formField.options"
              :label="$t(`label.${formField.label}`)"
              :multiple="formField.multiple || false"
              itemColor="background"
              :color="formField.color || 'surface'"
              :class="`form-${formField.key}`"
              :rules="formField.rules"
              @input="input"
            />
            <AppFormField
              v-if="
                (formField.key === 'guardian_relationship_other' &&
                  includeOtherGuardianRelationship) ||
                (formField.key === 'guardian_pronouns_other' &&
                  includeOtherPronoun)
              "
              v-model="formValues[formField.key]"
              dark
              :type="formField.formType"
              :label="$t(`label.${formField.label}`)"
              itemColor="background"
              :color="formField.color || 'surface'"
              :class="`form-${formField.key}`"
              @input="input"
            />

            <vue-tel-input-vuetify
              v-if="formField.key === 'guardian_mobile'"
              :preferredCountries="['SG', 'MY']"
              defaultCountry="SG"
              v-model="formValues[formField.key]"
              :label="$t('label.mobileNo')"
              mode=""
              required
              outlined
              dark
              :rules="formField.rules"
              @input="onNumberChange"
              @country-changed="updateCountry"
            ></vue-tel-input-vuetify>
            <div v-if="formField.key === 'guardian_gender'" class="mb-2 text-start">
              <div>{{ $t('label.gender') }}</div>
              <v-radio-group
                v-model="formValues.guardian_gender"
                row
                class="d-inline-block"
                @change="input"
              >
                <v-radio
                  dark
                  color="#FF2FF2"
                  v-for="(gender, genderIndex) in constantsData.gender"
                  :value="gender.value"
                  :key="genderIndex"
                >
                  <template v-slot:label>
                    <span class="white--text">{{ gender.description }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
              <CustomInput
                v-if="formValues.guardian_gender === 'other'"
                v-model="formValues.guardian_gender_other"
                class="d-inline-block ps-5"
                style="width: 40%"
                id="gender-other-id"
                dark
                type="text"
                :label="$t('label.pleaseSpecify')"
                @input="input"
              />
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div class="py-5 pageFooter text-center">
      <v-btn
        class="btn1 mb-3"
        :disabled="!(guardianInfoFormValid)"
        @click="save"
      >
        Save & Next 
      </v-btn>
      <div class="underlineBtn mb-3" @click="$emit('doItLater')">
        Do it later
      </div>
    </div>
  </div>
</template>

<script>
 import { i18nHelper, uiHelper, formValidationHelper } from '@/utils';
  import customFormValidationMixin from '@/mixin/custom-form-validation.mixin';
  import { LOCAL } from '@/constants';
  import { listService, i18nService } from '@/services';
  import CustomInput from '@/components/inputs/custom-input.vue';
  import {
    PROFILE_UPDATE_GUARDIAN_INFO,
    PROFILE_INITIAL_UPDATE_GUARDIAN_INFO_STATE
  } from '@/store/profile.module';

  export default {
    name: 'GuardianInfo',
    mixins: [customFormValidationMixin],
    props: {
      userInfo: {
        type: Object,
        default: () => ({})
      },
      isCreate: {
        type: Boolean,
        default: false
      }
    },
    components: {
      CustomInput
    },
    data: () => ({
      formValues: {
        guardian_relationship: null,
        guardian_relationship_other: null,
        guardian_first_name: null,
        guardian_last_name: null,
        guardian_gender: null,
        guardian_gender_other: null,
        guardian_pronouns: null,
        guardian_pronouns_other: null,
        guardian_mobile_dial_code: '+65',
        guardian_mobile: null,
        guardian_email: null
      },
      guardianInfoFormValid: false,
      currentLanguage: i18nService.getActiveLanguage(),
    }),
    computed: {
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      guardianInfoFormFields() {
        return [
          {
            key: 'guardian_relationship',
            label: 'relationshipWithTalent',
            formType: 'select',
            options: listService.getDynamicList(
              this.constantsData.guardian_relationship
            ),
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'guardian_relationship_other',
            label: 'pleaseSpecify',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'guardian_first_name',
            label: 'firstName',
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.firstName'),
              2,
              50
            )
          },
          {
            key: 'guardian_last_name',
            label: 'lastName',
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.lastName'),
              2,
              50
            )
          },
          {
            key: 'guardian_mobile',
            label: 'mobileNo',
            field: 'other',
            rules: this.concatRequiredRules(
              formValidationHelper.mobileNumberRules(
                this.formValues.guardian_mobile_dial_code,
                this.formValues.guardian_mobile
              )
            )
          },
          {
            key: 'guardian_email',
            label: 'email',
            rules: this.concatRequiredRules(formValidationHelper.emailRules())
          },
          {
            key: 'guardian_gender',
            label: 'guardian_gender',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'guardian_pronouns',
            label: 'pronouns',
            formType: 'select',
            options: listService.getDynamicList(this.constantsData.pronouns),
            rules: formValidationHelper.requiredRules(),
            multiple: true
          },
          {
            key: 'guardian_pronouns_other',
            label: 'pleaseSpecify',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          }
        ];
      },
      phoneCodes() {
        return listService.getPhoneCodeList(this.constantsData.country);
      },
      includeOtherGuardianRelationship() {
        return this.formValues.guardian_relationship === 'other';
      },
      includeOtherPronoun() {
        const otherPronounValueKey = this.constantsData.pronouns.find(
          (x) => x.key === 'OTHER'
        ).value;
        return (
          this.formValues?.guardian_pronouns?.find(
            (x) => x === otherPronounValueKey
          ) ?? false
        );
      },
      updateGuardianInfoComplete() {
        return this.$store.state.profile.updateGuardianInfo.complete;
      },
    },
    methods: {
      onNumberChange(v, v2) {
        this.formValues.guardian_mobile = v2.number.significant;
        this.input();
      },
      updateCountry(event) {
        this.formValues.guardian_mobile_dial_code = '+' + event.dialCode;
        this.input();
      },
      save() {
        if (!this.isCreate) {
          this.$store.dispatch(PROFILE_UPDATE_GUARDIAN_INFO, {
            data: this.formValues
          });
        } else {
          this.$emit('next', this.formValues);
        }
      },
      initialUpdateGuardianInfoState() {
        this.$store.dispatch(PROFILE_INITIAL_UPDATE_GUARDIAN_INFO_STATE);
        this.$emit('next', this.formValues);
      },
      next() {
        this.$emit('next', this.formValues);
      },
      input() {
        this.$emit('input', this.formValues);
      }
    },
    mounted() {
      this.formValues = {
        guardian_relationship: this.userInfo?.guardian_relationship,
        guardian_relationship_other: this.userInfo?.guardian_relationship_other,
        guardian_first_name: this.userInfo?.guardian_first_name,
        guardian_last_name: this.userInfo?.guardian_last_name,
        guardian_gender: this.userInfo?.guardian_gender,
        guardian_gender_other: this.userInfo?.guardian_gender_other,
        guardian_pronouns: this.userInfo?.guardian_pronouns,
        guardian_pronouns_other: this.userInfo?.guardian_pronouns_other,
        guardian_mobile_dial_code: this.userInfo?.guardian_mobile_dial_code,
        guardian_mobile: this.userInfo?.guardian_mobile,
        guardian_email: this.userInfo?.guardian_email
      };
    },
    watch: {
      // gender(val) {
      //   if (val !== 'other') this.formValues.genderOther = null;
      // },
      includeOtherGuardianRelationship(val) {
        if (!val) this.formValues.guardian_relationship_other = null;
      },
      includeOtherPronoun(val) {
        if (!val) this.formValues.guardian_pronouns_other = null;
      },
      updateGuardianInfoComplete() {
        let response = this.$store.state.profile.updateGuardianInfo;
        if (response.complete) {
          this.initialUpdateGuardianInfoState();
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  ::v-deep .btn1 {
    padding: 25px 50px !important;
  }
</style>
